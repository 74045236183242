<template>
  <div :class="isOpen ? 'modal modal-in-modal show d-block' : 'd-none'" id="remarkModal" tabindex="-1" aria-labelledby="remarkModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content adjustable">
        <div class="modal-header">
          <h5 class="modal-title" id="remarkModalLabel">{{$t('form.add', [$t('accounting.default_costs').toLowerCase()])}}</h5>
          <button type="button" class="btn-close" @click.prevent="$parent.setDefaultOpen(false)" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <template v-for="(option, optname) in options" :key="option.name">
            <div class="row pb-1">
              <div class="col-4">
                <FormItem :id="'default-'+optname" type="checkbox" v-model="option.active" :label="option.name" />
              </div>
              <div class="col-3"></div>
              <div class="col-2">
                <FormItem type="number" v-model="option.quantity" />
              </div>
              <div class="col-3">
                <FormItem v-model="options[optname]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="['value', 'currency_id']" />
              </div>
            </div>
            <div class="row my-2 ps-3" v-if="optname == 'coco'">
              Customs assistance
            </div>
          </template>
        </div>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-success" @click.prevent="save">{{$t('form.add', [''])}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['isOpen', 'currencies'],
        name: 'InvoiceDefaultCostPopup',
        data() {
            return {
                options: {}
            }
        },
        methods: {
            save(e){
              for(let optname in this.options){
                const opt = this.options[optname];
                if(opt.active){
                  let newCost = Number(opt.value);
                  let quantity = Number(opt.quantity);
                  this.$parent.invoice.invoice_lines.push({
                    quantity: quantity,
                    line_type: null,
                    description: opt.name,
                    sales_rate: newCost,
                    amount: null,
                    buying_rate: '0',
                    buying_rate_currency_id: opt.currency_id,
                    buying_rate_calculation_unit_id: null,
                    sales_rate_currency_id: opt.currency_id,
                    sales_rate_calculation_unit_id: null,
                    collect: '',
                    collect_currency_id: opt.currency_id,
                    supplier_id: null,
                    vat: '0'
                  });
                }
              }
              this.$parent.invoiceOnChange();
              this.$parent.setDefaultOpen(false);
            },
            reset(){
              this.options = {
                  "prep": {
                    active: false,
                    quantity: 1,
                    currency_id: 1,
                    value: 25,
                    name: 'Preparing B/L'
                  },
                  "cudo": {
                    active: false,
                    quantity: 1,
                    currency_id: 1,
                    value: 50,
                    name: 'Customs document'
                  },
                  "adfe": {
                    active: false,
                    quantity: 1,
                    currency_id: 1,
                    value: 30,
                    name: 'Administration fee'
                  },
                  "coco": {
                    active: false,
                    quantity: 1,
                    currency_id: 1,
                    value: 20,
                    name: 'Courier costs'
                  },
                  "cars": {
                    active: false,
                    quantity: 1,
                    currency_id: 1,
                    value: 7.50,
                    name: 'Cars'
                  },
                  "trhh": {
                    active: false,
                    quantity: 1,
                    currency_id: 1,
                    value: 10,
                    name: 'Trucks/HH'
                  },
                  "catr": {
                    active: false,
                    quantity: 1,
                    currency_id: 1,
                    value: 5,
                    name: 'Cars/Trucks'
                  }
                };
            },
        }
    }
</script>