import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/relation'

class RelationService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getTypes(){
        return http.get(`${this.getUrl()}/types`)
    }
    index(page, params, type) {
        var queryUrl = ''
        if (type) {
            queryUrl = `?type=` + type;
        }

        if (params && page) {
            let typeUrl = '';
            if (type) {
                typeUrl = `&type=` + type;
            }
            const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            queryUrl = `?page=` + page + typeUrl + `&` + queryParams
        }
        return http.get(`${this.getUrl()}` + queryUrl)
    }

    getRepresentatives() { 
        return http.get(`${this.getUrl()}?is_port_representative=1`)
    }
    getVatPercentages(officeId, withOffice = false){
        const qstring = withOffice ? '?with_office=true' : '';
        return http.get(`${this.getUrl()}/office/${officeId}/vats${qstring}`)
    }
    checkForDuplicates(id, vat_number){
        return http.post(`${this.getUrl()}/check-for-dupelicates`, {'id': id, 'vat_number': vat_number})
    }
    createCase(id, relation){
        return http.post(`${this.getUrl()}/${id}/create-case`, relation)
    }
}

export default new RelationService()