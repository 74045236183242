import {ApiService} from '@/services/ApiService'

const URL = '/api/payment-term'

class PaymentTermService extends ApiService {
    constructor(){
        super()
    } 
    getUrl(){
        return URL
    }

}

export default new PaymentTermService()