import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/mainport-project'

class MainportService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    find(voyageId, officeId) {
        return http.get(`${URL}/${voyageId}/${officeId}`)
    }
}

export default new MainportService()