<template>
    <div :id="moduleName.replaceAll(' ','-').replaceAll('&','and').toLowerCase()+'-popup'">
        <div class="modal fade" id="formModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true" @keydown.esc="close">
            <div class="modal-dialog" :class="(modalSize ? modalSize : 'modal-lg') + (hideFooter ? '' : ' modal-dialog-centered') + (moduleName == 'Voyage planning' ? '' : ' modal-dialog-scrollable')" :style="modalWidth ? {maxWidth: modalWidth} : {}">
                <div class="modal-content" :class="{'blue-header': blueHeader}" :style="{height: (modalHeight ? modalHeight : 'auto')}">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <span v-if="(!itemId || itemStatus === 'draft') && !isCopy">{{$t('form.add', [moduleName])}}</span>
                            <span v-else-if="!itemId && isCopy">{{$t('sales.new_x', [moduleName])}}</span>
                            <span v-else>{{$t('form.edit', [moduleName])}}</span>
                            <span id="detailsSpan"></span>
                        </h5>
                        
                        <button id="modal-close-button" type="button" class="d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        <button type="button" id="fakeCloseBtn" class="btn-close" @click="close"></button>
                    </div>
                    <div class="modal-body" :data-nofooter="hideFooter">

                        <div :class="{ 'd-none': !loading }">
                            <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}}
                        </div>
                        <form :class="{ 'd-none': loading, 'h-100': !loading }" :id="'form-'+moduleName.replaceAll(' ', '-')" autocomplete="off" @keydown="onKeyDown" onsubmit="return false">
                            <slot></slot>
                        </form>
                    </div>
                    <div class="modal-footer" v-if="!hideFooter">
                        <slot name='modal-footer'></slot>   
                        <button id="savebutton" type="button" class="btn btn-success" @click.prevent="save"><i class="fa fa-spinner fa-spin me-1"></i>{{$t('form.save')}}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{$t('form.delete') + ' ' + moduleName.toLowerCase()}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        {{$t('form.are_you_sure_delete')}} <b>{{itemName}}</b>?
                    </div>
                    <div class="modal-footer d-flex">
                        <button type="button" class="btn btn-dark" data-bs-dismiss="modal">{{$t('form.no')}}</button>
                        <button type="button" class="btn btn-danger" @click.prevent="del">{{$t('form.yes')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormPopup',
        emits: ['save', 'del'],
        props: ['moduleName', 'itemId', 'itemName', 'loading', 'modalSize', 'modalHeight', 'modalWidth', 'isNew', 'isCopy', 'itemStatus', 'hideFooter', 'dontRemoveHash', 'blueHeader'],
        data() {
            return {
                shouldAskOnClose: false
            }
        },
        methods: {
            save(e) {
                this.shouldAskOnClose = false;
                this.$emit("save", e);
            },
            del() {
                this.shouldAskOnClose = false;
                this.$emit("del");
            },
            onKeyDown(e){
                if(["INPUT", "LABEL", "TEXTAREA"].includes(document.activeElement.tagName)) this.shouldAskOnClose = true;
            },
            getAutoSaveEnabled(){
                return this.shouldAskOnClose;
            },
            setAutoSaveDisabled(){
                this.shouldAskOnClose = false;
            },
            close(){
                if((this.isNew || this.shouldAskOnClose) && !confirm(this.$t('form.are_you_sure_close')))
                    return;
                if(window.location.hash && !this.dontRemoveHash){
                    window.location.hash = '';
                }
                this.shouldAskOnClose = false;
                const typeaheads = document.getElementById('formModal').querySelectorAll('.simple-typeahead-reset');
                typeaheads.forEach((el) => el.nextElementSibling.classList.remove('is-invalid'));
                document.querySelector("#modal-close-button").click();
                const dSpan = document.getElementById('detailsSpan')
                if(dSpan) dSpan.innerHTML = '';
                if(window.activeChannel){
                    window.Echo.leaveChannel(window.activeChannel);
                }
                window.setTimeout(() => {
                    window.autocompleteActive = false;
                    this.$parent.itemFormRef.active(false);
                    const el = document.querySelector("#myTab > li > button");
                    if(el) el.click();
                }, 200);
                if(this.$route.name == "Bills of Lading"){
                    window.setTimeout(() => {
                        const el2 = document.querySelector("#new-bl-overview button:focus");
                        if(el2){
                            el2.parentElement.parentElement.classList.add('selected');
                        }
                    }, 400);
                }
            }
        }
    }

</script>

<style>
    .blue-header > .modal-header{
        color: #fff;
        background-color: #0d6efd;
        border-color: #0d6efd;
        font-family: 'DM Sans', sans-serif;
    }
    .blue-header > .modal-header > #fakeCloseBtn{
        filter: invert(1)
    }
    .blue-header > .modal-header h5{
        font-weight: bold
    }
    .blue-header{
        min-height: calc(100vh - 50px);
    }
    .clickable {cursor: pointer}
    .v-toast { z-index: 1060 !important }
    .required-label::after{
        color: #000;
        content: ' *'
    }
    .form-label-above {
        font-weight: bold;
        font-size: .785rem;
    }
    .far.fa-close{
        position: relative;
        bottom: 2px
    }
    .is-valid{
        border-color: #c6c6c6 !important;
        background: none !important;
    }
    #savebutton:not([disabled]) > i, #savebutton[inert] > i{
        display: none;
    }
    /* Remove spinners */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }
</style>